@import 'old';
@import 'components/dashboard/dashboard-problem-orders';
// @import 'components/walden-routing';

// Routing
@import 'components/routing/routing-map';
@import 'components/routing/routing-control-panel';
@import 'components/routing/routing-control-widget/routing-control-widget';
@import 'components/routing/routing-control-widget/routing-control-widget-selected';
@import 'components/calendar/calendar';
@import 'components/chat/chat';
@import 'components/utility/utility';
@import 'components/holiday-order-edit';
@import 'components/env-banner';
@import 'share-config';
@import 'ember-power-calendar';
@import 'components/member-payment-info';
@import 'components/sp';
$primary-color: gray; // For the ember-cli-loaders.
@import 'ember-cli-loaders/loaders';
.demo-calendar-small {
  @include ember-power-calendar($cell-size: 30px);
}
.demo-calendar-medium {
  @include ember-power-calendar($cell-size: 50px);
}
.demo-calendar-big {
  @include ember-power-calendar($cell-size: 150px, $primary-color: red);
}

.demo-calendar-medium {
  margin: 0px auto !important;
}

.ember-power-calendar-day:hover .dmeta {
  color: #333 !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@import 'ember-power-select';
