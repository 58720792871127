#routing-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 35%;
  left: 0;
}

#info-notification {
  width: 36em;
  position: absolute;
  top: 2%;
  left: 1%;

  background: #e0e0e0;
  color: black;
  z-index: 99;
  border-radius: 3px;
  padding: 1em;
}

$service-day-1: #2243b6;
$service-day-2: #0066ff;
$service-day-3: #9de093;
$service-day-4: #3aa655;
$service-day-5: #00cccc;
$service-day-6: #008080;
$service-day-7: #47abcc;
$service-day-8: #ffff99;
$service-day-9: #ffeb00;
$service-day-10: #ecb176;
$service-day-11: #9e5b40;
$service-day-12: #ff681f;
$service-day-13: #ff8833;
$service-day-14: #733380;
$service-day-15: #c154c1;
$service-day-16: #fc74fd;
$service-day-17: #e62e6b;
$service-day-18: #00468c;
$service-day-19: #0066cc;
$service-day-20: #4d8c57;
$service-day-21: #3aa655;
$service-day-22: #00cccc;
$service-day-23: #008080;
$service-day-24: #47abcc;
$service-day-25: #ffff99;
$service-day-26: #ffeb00;
$service-day-27: #ecb176;
$service-day-28: #9e5b40;
$service-day-29: #ff681f;
$service-day-30: #ff8833;
$service-day-31: #733380;
$service-day-32: #c154c1;
$service-day-33: #fc74fd;

.day-1 {
  background: $service-day-1;
}

.day-2 {
  background: $service-day-2;
}

.day-3 {
  background: $service-day-3;
}

.day-4 {
  background: $service-day-4;
}

.day-5 {
  background: $service-day-5;
}

.day-6 {
  background: $service-day-6;
}

.day-7 {
  background: $service-day-7;
}

.day-8 {
  background: $service-day-8;
}

.day-9 {
  background: $service-day-9;
}

.day-10 {
  background: $service-day-10;
}

.day-11 {
  background: $service-day-11;
}

.day-12 {
  background: $service-day-12;
}

.day-13 {
  background: $service-day-13;
}

.day-14 {
  background: $service-day-14;
}

.day-15 {
  background: $service-day-15;
}

.day-16 {
  background: $service-day-16;
}

.day-17 {
  background: $service-day-17;
}

.day-18 {
  background: $service-day-18;
}

.day-19 {
  background: $service-day-19;
}

.day-20 {
  background: $service-day-20;
}

.day-21 {
  background: $service-day-21;
}

.day-22 {
  background: $service-day-22;
}

.day-23 {
  background: $service-day-23;
}

.day-24 {
  background: $service-day-24;
}

.day-25 {
  background: $service-day-25;
}

.day-26 {
  background: $service-day-26;
}

.day-27 {
  background: $service-day-27;
}

.day-28 {
  background: $service-day-28;
}

.day-29 {
  background: $service-day-29;
}

.day-30 {
  background: $service-day-30;
}

.day-31 {
  background: $service-day-31;
}

.day-32 {
  background: $service-day-32;
}

.day-33 {
  background: $service-day-33;
}
