.logo {
  width: 71px;
  margin-left: -2px;
  margin-top: -21px;
  margin-bottom: -21px;
}

body,
html {
  height: 100%;
  width: 100%;
}

.mt-edit-cuts .selected,
.dr-edit-cuts .selected {
  color: #ffffff;
  background: #9f86ff;
}

#active-orders {
  position: absolute;
  top: 4em;
  right: 0;
  left: 0px;
  width: 100%;
  height: 20em;
  box-shadow: 0 4px 2px -2px gray;
  z-index: 99;
  background: #fff;
}

.transaction-details-table tr td:first-child {
  font-weight: bold;
}

.t-note {
  font-style: italic;
  font-size: 0.9em;
}

.cursor-point {
  cursor: pointer;
}

.cg-list tr {
  cursor: hover !important;
}

.cut-select,
.cuts-in-group {
  max-height: 300px;
  overflow-y: scroll;
  cursor: pointer;
}

.cut-select .icon {
  display: none;
}
.cuts-in-group .icon {
  display: inline-block;
  color: rgb(74, 190, 74);
}

.marked-remove {
  background: rgb(255, 56, 56) !important;
}

.two:hover ~ hr {
  margin-left: 50% !important;
}

.one:hover ~ hr {
  margin-left: 0 !important;
}

.route-ui-tab {
  width: 50%;
  margin: 0;
  float: left;
  font-size: 1.4em;
  padding: 1em;
  text-align: center;
  background: #212121;
}

.route-ui-tab.active {
  /* border-bottom: 3px solid #e1253b; */
}

.rt-shares:hover ~ hr {
  margin-left: 50%;
}

#rt-ui-confirm {
  width: 30%;
  margin: 0px auto;
  text-align: center;
  padding: 1em;
  background: #2d2d2d;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 2px 2px 4px #000000;
}

#rt-ui-ctrl .btn {
  margin: 0 15px;
  width: 256px;
}

#markerLayer img {
  animation: pulse 0.5s infinite alternate !important;
  -webkit-animation: pulse 0.5s infinite alternate !important;
  transform-origin: center !important;
  -webkit-transform-origin: center !important;
}

#view-orders {
  display: inline-block;
  float: right;
  font-size: 1.2em;
  background: #eaeaea;
  margin-top: 5px;
  padding: 0.8em;
  transition: 0.5s;
  cursor: pointer;
}

#view-orders:hover {
  transition: 0.5s;
  background: #cecece;
}

#order-header {
  padding: 1em;
}

#route-detail-pane {
  display: block;
  background: #fff;
  border-radius: 4px;
  width: 30%;
  height: 25em;
  position: absolute;
  bottom: 4em;
  z-index: 102;
  right: 6em;
  box-shadow: 2px 2px 4px #868585;
  padding: 1em;
}

#route-detail-pane hr {
  margin-top: 0px;
  margin-bottom: 1em;
}

#route-detail-pane h2 span {
  font-size: 0.8em;
}

#route-manage {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #484848;
  height: 400px;

  width: calc(100% - 70px);
}

.mu-parent i {
  padding: 1.2em;
  cursor: pointer;
  float: right;
  border-left: 1px solid #2e82ff;
}

.route-nav {
  padding: 15px;
  overflow: auto;
  border-bottom: 1px solid #000;
  background: #333;
}

.time-toggle {
  float: right !important;
}

.route-nav .btn-group {
  margin-left: 15px !important;
}

.route-nav h3 {
  display: inline-block;
  float: left;
  color: #fff;
}

#route-manage h5 {
  text-align: center;

  color: #fff;
  margin: 0px auto;
  border-bottom: 1px solid #5f5f5f;
  padding-bottom: 15px;
}

.col-30 {
  float: left;
  display: inline-block;
  width: 33.33%;
  padding: 15px;
}

.left {
  float: none !important;
  position: absolute !important;
  left: calc(50% - 120px);
}

#route-manage table {
  color: #fff;
}

#route-manage td {
  padding: 15px;
}

#route-manage tr {
  border-bottom: 1px dotted #585656;
}

#route-manage .ttitle {
  font-weight: bold;
}

#route-manage table .btn {
  margin-top: -5px;
  margin-left: 15px;
}

#route-manage a {
  text-decoration: none;
  color: #ff6900;
}

.daterangepicker.ltr .calendar.left {
  clear: left !important;
}

.green {
  color: #4ef74e;
}

.orange {
  color: #fff446;
}

.right {
  text-align: right;
  padding-right: 64px !important;
}

.container-fluid-spacious {
  margin-left: 70px !important;
  width: calc(100% - 70px) !important;
}

.member-search {
  width: 400px !important;
}

.member-search-addr {
  width: 400px !important;
  margin-left: 1em !important;
}

.manage-credits {
  color: rgb(47, 177, 47);
  cursor: pointer;
}

.member-search-results {
  margin-top: -31px;
}

.meta-title {
  display: inline-block;
  width: 175px;
  font-weight: bold;
  line-height: 40px;
}

.action-item-meta {
}

.reference-info {
  font-size: 13px !important;
}

.action-desc {
  height: 100px;
}

.submit-action-item {
  margin: 0px auto !important;
}

.history-text {
  position: absolute;
  right: 650px;
}

.he-check {
  margin-right: 50px;
}

#calendar .col {
  /* height: 145px; */
  border: 1px solid #333;
}
.cal-day {
  font-size: 2.1em;
  padding: 0px !important;
  border: 1px solid #dcdcdc;
}

.btn-save {
  background: #4b7cc5;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 1em;
  margin-top: 21px;
}

.btn-save:hover {
  background: #426cac;
}

#calendar .col {
  /* height: 145px; */
  text-align: center;
  vertical-align: middle;
  /* font-size: 52px; */
  padding-top: 33px;
  cursor: pointer;
  margin-left: -1px;
  margin-top: -1px;
}

#centroid-map {
  width: 100%;
  height: 139px;
  /* background: #eee; */
}

.cal-day:hover .service-day-edit {
  display: block;
}

.service-day-edit {
  font-size: 0.4em;
  margin: 1em;
  background: #3498db;
  color: #fff;
  font-weight: bold;
  padding: 0.4em;
  border-radius: 3px;
  position: absolute;
  width: 85%;
  bottom: 34px;
  display: none;
}

.centroid-listing {
}

.c-listing {
  border-radius: 2px;
  margin-bottom: 1em;
  /* border: 1px solid rgb(184, 184, 184); */
}

.c-meta {
  width: 100%;
  height: 100%;
}

.c-meta .title {
  font-weight: bold;
  background: rgb(0, 166, 255);
  color: #ffffff;
  position: relative;

  height: 50px !important;
  display: block;
  height: 1.2em;
}

.c-routing {
  width: 60%;
  display: inline-block;
  background: #eee;
  font-size: 0.95em;
  overflow: hidden;
}

.c-routing .row {
  background: #eee;
  padding: 0em 1.5em !important;
}

.c-routing .row:nth-child(even) .col {
  background: #e6e6e6;
}

.c-route-num {
  padding: 0.4em 1em;
  background: #e0e0e0;
}

.c-name {
  display: inline-block;
  font-size: 1.2em;
  padding: 12px;
}

.centroid-ww-up {
  font-size: 1em;
  background: #0093e2;
  padding: 1.2em;
  float: right;
  border-left: 1px solid #0083ca;
  cursor: pointer;
}

.centroid-lock {
  font-size: 1em;
  background: #0093e2;
  padding: 1.2em;
  float: right;
  border-left: 1px solid #0083ca;
  cursor: pointer;
}

.centroid-packed {
  font-size: 1em;
  background: #0093e2;
  padding: 1.2em;
  float: right;
  border-left: 1px solid #0083ca;
  cursor: pointer;
}

.centroid-dispatch {
  font-size: 1em;
  background: #0093e2;
  padding: 1.2em;
  float: right;
  border-left: 1px solid #0083ca;
  cursor: pointer;
}

.centroid-picksheet {
  font-size: 1em;
  background: #006fab;
  padding: 1.2em;
}

.c-meta .title i:hover {
  color: #ffee00;
}

.centroid-picksheet:hover {
  cursor: pointer;
}

.service-day-edit:hover {
  background: #2b7bb1;
}

.day-index {
  padding-top: calc(50% - 1.4em);
  font-weight: bold;
}

.day-listing {
  display: inline-block;
  border: 1px dashed #333;
  margin: 1em;
  padding: 1em;
  cursor: pointer;
}

.weekend {
  background: #f9f9f9;
}

.locked-route {
  position: absolute;
  top: 10px;
  display: none;
  right: 10px;
  font-size: 0.7em;
  color: #e74c3c;
}

.makeup-day {
  position: absolute;
  top: 10px;
  display: none;
  left: 10px;
  font-size: 0.7em;
  color: #3ce73c;
}

.day-meta {
  width: 100%;
  background: #f1f1f1;
  height: 25%;
  position: absolute;
  bottom: 0;
  color: #333;
  font-size: 0.5em;
}

.first-meta {
  background: #ececec;
}

.meta-col {
  width: 50%;
  display: inline-block;
  float: left;
  padding-top: 5%;
  height: 100%;
}

.day-meta row,
.day-meta col {
  margin: 0px !important;
  padding: 0px !important;
}

.day-listing:hover {
  background: #eee;
}

#calendar .col:hover {
  background: #eee;
}

#calendar-col {
  margin: 10px !important;
}

#calendar .col:first-child,
#calendar .col:last-child {
  color: #e8e7e7;
}

#calendar .header {
  font-size: 0.7em;
  /* height: 67px; */
  padding: 1em;
  background: #3498db;
  border-bottom: 4px solid #34495e !important;
  font-weight: bold;
  color: #fff;
}

#calendar .header:hover {
  background: #336388 !important;
}

.corner-link {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #e67e22 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
}

#login {
  margin: 0px auto;
  width: 500px;
  display: block;
  background: #eee;
  padding: 30px;
}

.custom-control-indicator {
  /*background: #e2e2e2 !important;*/
}

.dr-select-edit {
  margin-right: 4px;
  margin-bottom: 10px;
}

.addon-cat-sel {
}

.addon-marker {
  background: #b7b7b7;

  font-size: 12px;
}

/* loader */

.load-body {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  top: 4em;
  left: 0;
  right: 0;
  bottom: 0;
  background: #183029;
  opacity: 0.9;
  transition: 0.5s;
}

.load-body h1 {
  color: #fff;
  position: absolute;
  /*margin: -50px 0 0 -74px;*/
  margin: -113px 0 0 -250px;
  left: 50%;
  top: 50%;
  width: 15em;
  opacity: 1 !important;
}

.loader-wrapper {
  width: 148px;
  height: 100px;
  position: absolute;
  margin: -50px 0 0 -74px;
  left: 50%;
  top: 60%;
  opacity: 1 !important;
}

.loader {
  width: 148px;
  height: 100px;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 1 !important;
}
.loader:after {
  content: '';
  top: auto;
  position: absolute;
  display: block;
  animation: shadow 1.2s infinite linear;
  -moz-animation: shadow 1.2s infinite linear;
  bottom: 0em;
  left: 0;
  height: 0.25em;
  width: 1em;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.3;
  opacity: 1 !important;
}

.roller,
.roller:last-child {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: rollercoaster 1.2s infinite linear;
  -webkit-transform: rotate(135deg);
  -moz-animation: rollercoaster 1.2s infinite linear;
  -moz-transform: rotate(135deg);
  animation: rollercoaster 1.2s infinite linear;
  transform: rotate(135deg);
  opacity: 1 !important;
}

.roller:last-child {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-45deg);
  -webkit-animation: rollercoaster2 1.2s infinite linear;
  -moz-transform: rotate(-45deg);
  -moz-animation: rollercoaster2 1.2s infinite linear;
  transform: rotate(-45deg);
  animation: rollercoaster2 1.2s infinite linear;
  opacity: 1 !important;
}

.roller:before,
.roller:last-child:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background: #ff6900;
  border-radius: 50%;
  opacity: 1 !important;
}

@-webkit-keyframes rollercoaster {
  0% {
    -webkit-transform: rotate(135deg);
  }
  8% {
    -webkit-transform: rotate(240deg);
  }
  20% {
    -webkit-transform: rotate(300deg);
  }
  40% {
    -webkit-transform: rotate(380deg);
  }
  45% {
    -webkit-transform: rotate(440deg);
  }
  50% {
    -webkit-transform: rotate(495deg);
    opacity: 1;
  }
  50.1% {
    -webkit-transform: rotate(495deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(495deg);
    opacity: 0;
  }
}
@-webkit-keyframes rollercoaster2 {
  0% {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -webkit-transform: rotate(-45deg);
  }
  58% {
    -webkit-transform: rotate(-160deg);
  }
  70% {
    -webkit-transform: rotate(-240deg);
  }
  80% {
    -webkit-transform: rotate(-300deg);
  }
  90% {
    -webkit-transform: rotate(-340deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
  }
}
@-webkit-keyframes shadow {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(65px) scale(0.5, 0.5);
  }
  8% {
    -webkit-transform: translateX(30px) scale(2, 2);
  }
  13% {
    -webkit-transform: translateX(0px) scale(1.3, 1.3);
  }
  30% {
    -webkit-transform: translateX(-15px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: translateX(60px) scale(1.2, 1.2);
    opacity: 0.3;
  }
  60% {
    -webkit-transform: translateX(130px) scale(2, 2);
    opacity: 0.05;
  }
  65% {
    -webkit-transform: translateX(145px) scale(1.2, 1.2);
  }
  80% {
    -webkit-transform: translateX(120px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  90% {
    -webkit-transform: translateX(80px) scale(0.8, 0.8);
  }
  100% {
    -webkit-transform: translateX(60px);
    opacity: 0.3;
  }
}
/* Moz */
@-moz-keyframes rollercoaster {
  0% {
    -moz-transform: rotate(135deg);
  }
  8% {
    -moz-transform: rotate(240deg);
  }
  20% {
    -moz-transform: rotate(300deg);
  }
  40% {
    -moz-transform: rotate(380deg);
  }
  45% {
    -moz-transform: rotate(440deg);
  }
  50% {
    -moz-transform: rotate(495deg);
    opacity: 1;
  }
  50.1% {
    -moz-transform: rotate(495deg);
    opacity: 0;
  }
  100% {
    -moz-transform: rotate(495deg);
    opacity: 0;
  }
}
@-moz-keyframes rollercoaster2 {
  0% {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -moz-transform: rotate(-45deg);
  }
  58% {
    -moz-transform: rotate(-160deg);
  }
  70% {
    -moz-transform: rotate(-240deg);
  }
  80% {
    -moz-transform: rotate(-300deg);
  }
  90% {
    -moz-transform: rotate(-340deg);
  }
  100% {
    -moz-transform: rotate(-405deg);
  }
}
@-moz-keyframes shadow {
  0% {
    opacity: 0.3;
    -moz-transform: translateX(65px) scale(0.5, 0.5);
  }
  8% {
    -moz-transform: translateX(30px) scale(2, 2);
  }
  13% {
    -moz-transform: translateX(0px) scale(1.3, 1.3);
  }
  30% {
    -moz-transform: translateX(-15px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  50% {
    -moz-transform: translateX(60px) scale(1.2, 1.2);
    opacity: 0.3;
  }
  60% {
    -moz-transform: translateX(130px) scale(2, 2);
    opacity: 0.05;
  }
  65% {
    -moz-transform: translateX(145px) scale(1.2, 1.2);
  }
  80% {
    -moz-transform: translateX(120px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  90% {
    -moz-transform: translateX(80px) scale(0.8, 0.8);
  }
  100% {
    -moz-transform: translateX(60px);
    opacity: 0.3;
  }
}
/* No-prefix */
@keyframes rollercoaster {
  0% {
    transform: rotate(135deg);
  }
  8% {
    transform: rotate(240deg);
  }
  20% {
    transform: rotate(300deg);
  }
  40% {
    transform: rotate(380deg);
  }
  45% {
    transform: rotate(440deg);
  }
  50% {
    transform: rotate(495deg);
    opacity: 1;
  }
  50.1% {
    transform: rotate(495deg);
    opacity: 0;
  }
  100% {
    transform: rotate(495deg);
    opacity: 0;
  }
}
@keyframes rollercoaster2 {
  0% {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: rotate(-45deg);
  }
  58% {
    transform: rotate(-160deg);
  }
  70% {
    transform: rotate(-240deg);
  }
  80% {
    transform: rotate(-300deg);
  }
  90% {
    transform: rotate(-340deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes shadow {
  0% {
    opacity: 0.3;
    transform: translateX(65px) scale(0.5, 0.5);
  }
  8% {
    transform: translateX(30px) scale(2, 2);
  }
  13% {
    transform: translateX(0px) scale(1.3, 1.3);
  }
  30% {
    transform: translateX(-15px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  50% {
    transform: translateX(60px) scale(1.2, 1.2);
    opacity: 0.3;
  }
  60% {
    transform: translateX(130px) scale(2, 2);
    opacity: 0.05;
  }
  65% {
    transform: translateX(145px) scale(1.2, 1.2);
  }
  80% {
    transform: translateX(120px) scale(0.5, 0.5);
    opacity: 0.1;
  }
  90% {
    transform: translateX(80px) scale(0.8, 0.8);
  }
  100% {
    transform: translateX(60px);
    opacity: 0.3;
  }
}

#loader2:after {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
#loader2 .roller {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

#loader3:after {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
#loader3 .roller {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

/* share config */

#meat-selection {
}

.meat-type-error {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  background: #ff4545;
  color: #fff;
  width: 100%;
  display: none;
}

.check-option.cut-list-option {
  border: 0 !important;
  color: #5c95b2 !important;
}

.check-option.cut-list-option.selected {
  border: 0 !important;
}

.cut-list-option span {
  width: 100%;
}

#meat-selection .choice {
  display: inline-block;
  float: left;
  width: 262px;
  height: 262px;
  margin-right: 30px;
  border-radius: 3px;
  box-shadow: 0px 0px 60px #d0d0d0;
  position: relative;
  cursor: pointer;
}

#meat-selection .choice:last-child {
  margin-right: 0px !important;
}

#meat-selection .selected {
  outline: 2px solid #ff6900;
  box-shadow: 0px 0px 23px #969595;
}

#meat-selection .choice .title {
  display: block;
  height: 70px;
  background: #fff;
  margin-top: 24px;
  width: 100%;
  position: absolute;
  z-index: 10;
  text-align: center;
  color: #333;
  font-size: 18px;
  padding-top: 27px;
  text-transform: uppercase;
}

#meat-selection .choice .walden-check {
  position: absolute;
  top: 180px;
  z-index: 15;
  left: 50%;
  margin-left: -12px;
}

.meat-type-image {
  width: 263px;
}

.signup-title {
  margin-top: 30px;
}

.restrictions {
  display: block;
  padding-top: 70px;
  text-align: center;
}

.pork-products,
.dietary-restrictions {
  padding-top: 30px;
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 45px;
  overflow: hidden;
  margin-bottom: 45px;
  position: relative;
}

.pork-products .check-option:last-child {
  margin-right: 0px !important;
}

.pork-products .selected,
.dietary-restrictions .selected {
  border: 2px solid #ff6900;
  box-shadow: 0px 0px 6px #b1b1b1;
}

.dietary-restrictions .half-option:nth-child(even) {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.share-select {
  text-align: center;
  width: 570px;
  margin: 0px auto;
  overflow: auto;
  padding-bottom: 60px;
  padding-left: 5px;
  padding-top: 6px;
}

.share-select-info {
  display: none;
}

.share {
  display: inline-block;
  width: 265px;
  height: 415px;
  background: #fff;
  float: left;
  box-shadow: 0px 0px 5px #c7c7c7;
  padding: 20px 25px;
  line-height: 22px;
  position: relative;
  z-index: 2;
  border-radius: 4px;
  border: 3px solid transparent;
  cursor: pointer;
}

.share li {
  list-style-type: circle;
}

.share-total {
  font-size: 1.5em !important;
}

.share-select .selected {
  border: 3px solid #ff6900;
}

.share-select .selected:after {
  border: 3px solid #ff6900;
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  margin-left: -23px;
  top: 100%;
  left: 50%;
  box-sizing: border-box;
  z-index: 1;

  background: #fff;

  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: none !important;
}

.share-title {
  color: #333;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 19px;
  font-family: 'Futura LT W01 Bold', sans-serif !important;
  text-transform: uppercase;
}

.share p {
  text-align: left;
  position: absolute;
  background: #fff;
  z-index: 5;
  height: 163px;
  padding-right: 25px;
}

.share:first-child {
  margin-right: 30px;
}

.share-amount {
  overflow: auto;
  padding-bottom: 0px;
  margin-left: calc(50% - 115px);
}

.select-amount,
.select-size {
  width: 230px !important;
  margin-top: 10px;
}

.param-select {
  width: 180px !important;
  display: block;
}

.param-select span {
  color: #333;
  margin-bottom: 10px;
}

.people-select {
  /* float: right; */
}

.size-select {
  /* float: left; */
}

.meta-amount {
  text-align: center;
  text-align: left;
  padding-left: 250px;
  margin-top: -43px;
}

.meta-total {
  text-align: center;
  color: #222;
  font-weight: bold;
  font-size: 53px;
  padding-bottom: 20px;
  margin-top: 0.5em;
}

.share-freq {
  text-align: center;
  padding-bottom: 30px;
}

.share-freq .select,
.share-freq select {
  width: 204px !important;
  color: #5c95b2 !important;
  margin-top: 10px;
  display: block;
  margin: 0px auto;
}

.section-desc {
  text-align: center;
}

.preset-select {
  padding-top: 30px;
}

.preset {
  cursor: pointer;
  display: inline-block;
  width: 260px;
  height: 411px;
  box-shadow: 0px 0px 5px #c7c7c7;
  float: left;
  margin-right: 33px;
  position: relative;
}

.preset .title {
  font-size: 19px;
  color: #333;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 20px;
}

.preset:last-child {
  margin-right: 0px !important;
}

.preset-select .selected.custom:after {
  border: 3px solid #ff6900;
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  margin-left: -23px;
  top: 100%;
  left: 50%;
  box-sizing: border-box;
  z-index: 1;

  background: #fff;

  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: none !important;
}

.preset-radio {
  background: #fff;
  margin-bottom: -20px;
  position: absolute;
  z-index: 2;
  top: 126px;
  margin-left: -13px;
  left: 50%;
}

.preset-img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 219px;
  display: block;
  margin: 20px auto 0px auto;
}

.preset p {
  padding: 0px 23px;
  line-height: 22px;
  display: block;
  position: absolute;
  height: 138px;
  z-index: 12;
  background: #fff;
}

.cutlist-lane {
  width: 360px;
  min-height: 200px;
  float: left;
  padding-top: 30px;
  padding-bottom: 0px;
  margin-right: 30px;
  overflow: auto;
  margin-bottom: 0px;
}

.cutlist-lane:last-child {
  margin-right: 0px !important;
}

.cutlist-lane .title {
  color: #333;
  text-align: center;
  padding: 30px;
}

#cutlist {
  overflow: auto;
}

.cutlist-lane .check-option {
  border: 2px solid transparent;
  margin-top: 7px;
  margin-bottom: 5px;
}

.cutlist-lane .selected {
  border: 2px solid #ff6900;
}

.preset-select .selected {
  outline: 3px solid #ff6900 !important;
  box-shadow: 0px 0px 21px #949494;
}

.preset-select .walden-radio {
  padding: 3px 6px;
  font-size: 17px;
  width: 27px;
  height: 27px;
}

#addon-select {
  margin: 0px auto;
  width: 1146px;
  height: 490px;
  padding-left: 3px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.addon {
  display: inline-block;
  width: 260px;
  height: 402px;
  box-shadow: 0px 0px 5px #c7c7c7;
  margin-right: 33px;
  float: left;
  background: #fff;
  padding: 25px;
  text-align: center;
  position: relative;
}

.addon:last-child {
  margin-right: 0px !important;
}

.addon span {
  color: #333;
  margin-bottom: 25px;
}

.addon-select {
  width: 205px !important;
}

.addon .param-select {
  padding-top: 25px;
  position: absolute;
  bottom: 45px;
}

.addon-price {
  color: #333;
  font-size: 18px;
  /* float: right; */
  margin-top: 10px;
  position: absolute;
  bottom: 14px;
  right: 0px;
  left: 0px;
}

.btn-next {
  margin: 0px auto;
  width: 550px;
}

.addon-wrap {
  padding-bottom: 90px;
}

.negative .walden-check {
  color: #ff6464 !important;
  padding: 2px 5px;
}

.cutlist-wrap {
  width: 1150px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 60px;
  overflow: auto;
}

.section0,
.section1,
.section2 {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 5px;
}

.inform {
  font-size: 10px;
  margin-top: 10px;
}

.inform a {
  color: #333030;
  text-decoration: none;
}

.edit-day {
  background: #fff;
  padding: 0.2em;
  font-size: 12px;
  color: #333;
  box-shadow: 0px 0px 2px #333;
  cursor: pointer;
  position: absolute;
  width: 90%;
  z-index: 999;
}

.schist-list {
  width: 300px;
  padding: 0em;
  background: #eee;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.hist-item {
  padding: 1em;
  cursor: pointer;
}

.hist-item:nth-child(even) {
  background: rgb(211, 211, 211);
}

#sc-frame {
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  width: calc(100% - 300px);
  bottom: 0;
  height: 100%;
}

.inform a:hover {
  text-decoration: underline;
}

.show-addr-hist {
  cursor: pointer;
}

#shareConfigModal .modal-dialog {
  position: relative !important;
  display: table !important; /* This is important */
  overflow-y: auto !important;
  overflow-x: auto !important;
  width: auto !important;
  min-width: 300px !important;
}

/* SP Override */

.share p {
  height: 157px !important;
}

.share-amount {
  padding-bottom: 20px !important;
}

.modal-fix {
  width: inherit !important;
}

.modal-fix input[type='text'] {
  margin-bottom: 0 !important;
}
