$weekend-background: #f9f9f9;

#routing-calendar {
  .weekend {
    background-color: $weekend-background;
  }
}

.cal-util .col:hover {
  background: #27ae60;
  cursor: pointer;
  color: #fff;
}

.packday-select,
.packday-select a {
  color: #000 !important;
}

.packday-select:hover {
  background: #ccc !important;
  color: #000 !important;
}

.cal-day-link {
  color: #fff !important;
}

.cal-day-link:visited,
.cal-day-link:hover {
  color: #fff !important;
}

.inactive-day a:link,
.inactive-day a:hover,
.inactive-day a:visited {
  color: #555 !important;
}
