.env-banner {
  height: 50px;
  color: white;
  font: {
    size: 1.5em;
    weight: bold;
  }
  text-transform: uppercase;
  background-color: green;
  &.pqa {
    background-color: orange;
  }
}
