#routing-control-panel-nav {
  position: absolute;
  top: 65%;
  right: 0;
  bottom: 0;
  left: 0;
}

#routing-control-panel-contents {
  position: absolute;
  top: 72%;
  right: 0;
  bottom: 4%;
  left: 0;
}

.scroll-y {
  overflow-y: auto;
}
