#routing-control-widget {
  position: absolute;
  bottom: 40%;
  left: 65%;
  right: 2%;
  overflow-y: auto;
  max-height: 58%;
  .card {
    background: #252830;
  }
}
