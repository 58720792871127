.selected-zips-list {
  // min-height: 15em;
  max-height: 15em;
  overflow-y: auto;
  @extend .zip-list;
}

.zip-list {
  li {
    list-style: none;
    &:nth-child(odd) {
      background: #151515;
    }
    &:nth-child(even) {
      background: #232323;
    }
  }
}

.text-red {
  color: red;
}
