.addon-row {
  .addon-remove {
    transition: none;
    visibility: hidden;
  }
  &:hover {
    .addon-remove {
      visibility: visible;
    }
  }
}
