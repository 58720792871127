.share-type {
  background: #2f443e;
  padding: 2em;
}

.share-type-selection {
  width: 1020px;
  margin: 0px auto;
  padding-bottom: 1em;
}

.share-type-wrap {
  // padding-bottom: 3em;
}

#dietary-restrictions {
  /* display: none; */
  width: 50%;
  margin: 0px auto;
  overflow: auto;
  margin-bottom: 2em;
}
.share-type-description ul li {
  position: relative;
  color: #fff;
  font-size: 0.9em;
}

.cow-left {
  width: 115px;
  float: left;
  display: inline-block;
  margin-top: -75px;
}

.cow-right {
  width: 115px;
  float: right;
  display: inline-block;
  margin-top: -75px;
}

.cow-middle {
  width: 115px;
  display: inline-block;
  margin-top: -75px;
}

.cow-display {
  padding: 0px 5em;
  text-align: center;
  margin-top: 100px;
}

.price-preview-dollars {
  font-size: 3em;
  color: #f36c21;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  letter-spacing: 3px;
}

.cname-base {
  float: left;
}

.cname-middle {
  float: right;
}

.cname-end {
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cow-type-name {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  letter-spacing: 1px;
  text-align: center;
  position: relative;
  top: -18px;
  font-size: 0.8em;
}

.addon-description {
  padding: 1em 1em 2em 1em;
  text-align: center;
}

.addon-food-img {
  width: 50%;
  display: block;
  margin: 0px auto;
}

.cow-type-name span {
  width: 115px;
}

.addon-name-title {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  color: #f36c21;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.share-type-from {
  text-align: center;
  font-size: 0.7em;
  color: #fff;
  vertical-align: middle;
}

.rec-addon {
  background: #f1f1f2;
  height: 100%;
  // padding: 2em;
}

#monthly-addons {
  background: #d1af89;
  padding: 3em 3em 0 3em;
  background: url('/assets/addons/kraftbg.png');
  background-size: contain;
}

#monthly-addons .meat-hero {
  width: 55%;
  margin-top: 3em;
  margin-bottom: 2em;
}

#mid-wrapper {
  width: 1100px;
  margin: 0px auto;
}

#monthly-addons .section-title-small {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  color: #fff;
  letter-spacing: 1px;
}

#decorative-wrapper {
  width: 100%;
  background: url('/assets/addons/foodbg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}

.direction-down {
  width: 5em;
  margin: 0px auto;
  display: block;
}

.cut-category {
  border: 2px solid #333;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  letter-spacing: 1px;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
}

.group-name {
  margin-left: 2.5em;
}

.cut-group {
  border: 2px solid #333;
  padding: 0.5em 0.5em;
  margin-bottom: 1em;
  letter-spacing: 1px;
  width: 90%;
  position: relative;
}

.cut-group .conf-mark {
  position: absolute;
  top: -0.4em;
  left: -0.2em;
  width: 2.1em !important;
}

.base-groups .cut-group {
  width: 100%;
}

.cut-group.selected {
  border-color: #f36c21;
}

.addon-per-month {
  color: #f36c21;
  letter-spacing: 1px;
}

.how-much-to-order {
  display: inline-block;
  margin-left: 3em;
  cursor: pointer;
  color: #f36c21;
  font-weight: bold;
  letter-spacing: 1px;
  border-bottom: 2px solid #f36c21;
}

.number-of-servings {
  display: inline-block;
  margin-left: 3em;

  letter-spacing: 1px;
}

#share-meta-select {
  padding: 5em 8em 0 8em;
}

.share-frequency-display {
  vertical-align: super;
  display: inline-block;
  top: -0.2em;
  position: relative;
  color: #f36c21;
  margin-left: 1em;
}

.next-wrapper {
  text-align: center;
}
.next-button-sc {
  padding: 1em 4em;
  background: #f36c21;
  width: auto;
  display: inline-block;
  margin: 0px auto;
  color: #fff;
  margin-top: 5em;
  margin-bottom: 5em;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  cursor: pointer;
}

.frequency-check {
  padding: 0.3em;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  top: -0.5em;
  left: 7.5em;
}

.next-button-sc:hover {
  background: #f8752e;
}

.small-header {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  font-size: 1.2em;
  letter-spacing: 1px;
  margin-bottom: 0.2em;
}

#select-share-size {
  display: inline-block;
  width: 15em;
  border: 2px solid #333;
  margin-bottom: 5em;
  display: inline-block;
  background: url(/assets/walden-down-arrow-c.png) no-repeat 95% 57% #ffffff;
  background-size: 1.5em;
}

#share-frequency {
  display: inline-block;
  width: 15em;
  border: 2px solid #333;
  margin-bottom: 5em;
  display: inline-block;
  background: url(/assets/walden-down-arrow-c.png) no-repeat 95% 57% #ffffff;
  background-size: 1.5em;
  position: relative;
  top: -0.5em;
  left: 4em;
}

#progress-nav {
  display: block;
  width: 800px;
  position: absolute;
  top: -83px;
  right: 170px;
}

.content-wrap {
  position: relative;
}

#progress-nav .row {
  margin: 0;
  padding: 0;
}

#progress-nav .col {
  margin: 0;
  padding: 0;
}

.prog-wrap {
  width: 1140px;
  position: relative;
  margin: 0px auto;
  margin-top: -18px;
}

#progress-nav .row {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-left: 1px solid #b4bfbe;
  border-right: 1px solid #b4bfbe;
  border-top: 1px solid #e2e7e6;
  border-bottom: 1px solid #7a8e8c;
}

#progress-nav li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

#progress-nav a {
  text-align: center;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  letter-spacing: 2px;
  color: #7a8e8c;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5em;
}

#progress-nav a:hover {
  text-decoration: none;
}

#progress-nav a.active {
  color: #fff;
  background: #183029;
}

#share-frequency:after {
}

.substitute {
  padding-bottom: 1em;
  background-image: url('/assets/walden-down-arrow.png');
}

.substitute-arrow {
  display: inline-block;
  width: 3em;
}

.addon-selection {
  display: inline-block;
  width: 15em;
  border: 2px solid #333 !important;
  margin-bottom: 1em;
  display: inline-block;
  margin-top: 30%;
  width: 100% !important;
  background: url(/assets/walden-down-arrow-c.png) no-repeat 95% 57% #ffffff;
  background-size: 1.5em;
}

.addon-quantity {
  padding: 2em;
  padding-right: 3em !important;
}

.cut-group .conf-check {
  position: absolute;
}

.share-type-selected {
  width: 2em;
  height: 2em;
  display: block;
  margin: 0px auto;
  position: absolute;
  bottom: 2em;
  left: calc(50% - 1em);
  right: 50%;
  background-image: url('/assets/circle-unchecked.png');
  background-size: cover;
  background-repeat: no-repeat;
  /* content: '<img class="share-type-unselected" src="/assets/circle-unchecked.png" />'; */
}

.share-type.active .share-type-selected {
  background-image: url('/assets/circle-checked.png');
}

.cat-arrow {
  display: inline-block;
  width: 1em;
  float: right;
  padding-top: 8px;
}

.share-type-from .large {
  font-size: 2em;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  vertical-align: sub;
  margin-top: -10px;
}

.share-type-description {
  margin-top: 1em;
  margin-bottom: 4em;
}

.share-type-description ul li:before {
  content: '·';
  vertical-align: middle;
  left: -0.5em;
  position: absolute;

  /* line-height:20px; */
}

#dietary-show {
  font-family: 'Futura LT w01 Heavy', sans-serif !important;
  text-align: center;
  margin: 6em auto;
  padding: 0.5em;
  font-size: 0.75em;
  border: 2px solid #333;
  width: 25%;
  letter-spacing: 2px;
  position: relative;
  cursor: pointer;
}

#dietary-restrictions ul {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
}

.restriction-select {
  display: none !important;
}
#account-info {
  width: 700px;
  margin: 0 auto;
}

#account-info select {
  display: inline-block;
  width: 100%;
  border: 2px solid #333;
  margin-bottom: 5em;
  display: inline-block;
  background: url(/assets/walden-down-arrow-c.png) no-repeat 98% 57% #ffffff;
  background-size: 1.5em;
  margin-bottom: 0;
}

.account-header {
  display: block;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 1em;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  letter-spacing: 2px;
  margin-top: -1px;
}

.thick {
  border: 2px solid #333;
  text-shadow: none;
  width: 100%;
  margin-top: 1em !important;
}

.thick:focus {
  text-shadow: none !important;
  border: 2px solid #333;
}

textarea.thick {
  border: 2px solid #333 !important;
}

.account-next {
  padding: 1em 4em;
  background: #f36c21;
  width: auto;
  display: inline-block;
  margin: 0px auto;
  color: #fff;
  margin-top: 2em;
  margin-bottom: 2em;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  cursor: pointer;
}

.account-create {
  padding: 1em 4em;
  background: #f36c21;
  width: 75%;
  display: inline-block;
  margin: 0px auto;
  color: #fff;
  margin-top: 4em;
  margin-bottom: 4em;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  cursor: pointer;
}

.futura {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
}

.futura-book {
  font-family: 'Futura LT W01 Book', sans-serif !important;
}

.account-next:hover,
.account-create:hover {
  background: #f8752e;
}

.checkmark {
  display: block;
  height: 70px;
  position: relative;
  width: 70px;
  margin: -93px auto 0 auto;
}

.checkmark:after {
  -webkit-animation: check 0.8s;
  -moz-animation: check 0.8s;
  -o-animation: check 0.8s;
  animation: check 0.8s;
}

.checkmark:after {
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid #2ecc71;
  border-top: 15px solid #2ecc71;
  content: '';
  display: block;
  height: 80px;
  left: 0px;
  position: absolute;
  top: 100px;
  width: 40px;
}
.section-title-small {
  text-align: center;
  font-size: 1.3em;
  color: #f36c21;
  margin-top: 2em;
  letter-spacing: 1px;
}

#dietary-restrictions ul li {
  list-style-type: none;
  display: inline-block;
  width: 50%;
  margin: 0;
  padding: 0;
  float: left;
}

#dietary-restrictions ul li:nth-child(even) {
  padding-right: 1em;
}

#dietary-restrictions ul li:nth-child(odd) {
  padding-left: 1em;
}

#dietary-restrictions ul li label {
  width: 100%;
}

.down-arrow-boxed {
  background-image: url('/assets/walden-down-arrow.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
  border-left: 2px solid #333;
}
.meat-hero {
  font-family: 'Futura LT w01 Heavy', sans-serif !important;
  font-size: 1.4em;
  width: 53%;
  margin: 0px auto;
  text-align: center;
  padding-top: 1em;
  letter-spacing: 1px;
}

.share-type-image {
  height: 3em;
  margin: 0px auto;
  display: block;
}

.share-type.active {
  outline: 3px solid #f36c21;
}

.share-type-title {
  color: #f36c21;
  font-family: 'Futura LT w01 Heavy', sans-serif !important;
  text-align: center;
  padding: 1em;
  letter-spacing: 1px;
}

.type-hero {
  font-family: 'Futura LT w01 Heavy', sans-serif !important;
  font-size: 1.4em;
  width: 53%;
  margin: 0px auto;
  text-align: center;
  padding-top: 1em;
  letter-spacing: 1px;
  color: #fff;
  padding-bottom: 60px;
}

#meat-selection {
  width: 50%;
  margin: 0px auto;
}

#meat-selection input {
  display: none;
}

.conf-check {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #333;
  position: relative;
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.conf-check-box {
  padding: 0.5em;
  border: 2px solid #333;
  width: 100%;
  display: block;
  overflow: auto;
}

.conf-check-box .conf-check {
  float: left;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.1em;
}

.conf-check-box .conf-mark {
  width: 1.8em !important;
  left: -0.2em;
  top: -0.5em;
}

.conf-check-text {
  display: inline-block;
  float: left;
  padding: 0 1em;
}

.conf-mark {
  position: absolute;
  top: -0.8em;
  left: -0.4em;
  width: 2.6em !important;
  // display: none;
}

.conf-check.active .conf-mark {
  display: block;
}
.center {
  margin: 0px auto;
}
.contact-msg {
  max-width: 575px;
  min-width: 575px;
  resize: none;
}

.preset .title {
  text-transform: uppercase;
}

.addon-title {
  text-transform: uppercase;
}

.meat-type-col img {
  width: 100%;
}

.contact-msg {
  max-width: 575px;
  min-width: 575px;
  resize: none;
}

.preset .title {
  text-transform: uppercase;
}

.addon-title {
  text-transform: uppercase;
}

.meat-type-col img {
  width: 100%;
}

#meat-selection .choice {
  width: 39% !important;
  position: relative !important;
  float: left !important;
  height: auto !important;

  /*height: 10em !important;*/
  /*    overflow: auto !important;
    display: block;
    margin-bottom: 2em;
    margin-left: 2em !important;
    margin-right: 0px !important;*/
  display: inline-block;
  /* float: left;
    width: 262px;
    height: 262px;
    margin-right: 30px;
    border-radius: 3px;
    box-shadow: 0px 0px 60px #D0D0D0;
    position: relative;
    cursor: pointer;*/
}
#meat-selection .choice:nth-child(odd) {
  margin-left: 6% !important;
  margin-right: 0px !important;
  float: left !important;
}

#meat-selection .choice:nth-child(even) {
  margin-right: 6% !important;
  margin-left: 0px !important;
  float: right !important;
}

#meat-selection .choice:after {
  content: '';
  display: block;
  padding-bottom: 40% !important;
}

#meat-selection {
  text-align: center;
}

#meat-selection .choice .title {
  display: block;
  height: auto;
  background: #fff;
  /*margin-top: 24px;*/
  margin-top: -0px !important;
  width: 100%;
  position: absolute;
  z-index: 10;
  text-align: center;
  color: #333;
  font-size: 1em;
  padding-top: 1em;
  text-transform: uppercase;
  clear: both;
}

.restrictions {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.restrictions .btn {
  width: 100% !important;
  margin: 0em !important;

  padding: 10px !important;
  box-sizing: border-box !important;
}

.restrictions .pad-left-15 {
  padding-left: 0px !important;
  padding: 1em !important;
}

.walden-green {
  background: #163028;
}

.walden-green .section-title {
  color: #fff;
  padding: 60px;
}

hr.solid {
  height: 1px;
  border-bottom: 2px solid #333;
  margin-left: 1em;
  margin-right: 1em;
}

.conf-check {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #333;
  position: relative;
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.conf-check-box {
  padding: 0.5em;
  border: 2px solid #333;
  width: 100%;
  display: block;
  overflow: auto;
}

.conf-check-box .conf-check {
  float: left;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.1em;
}

.conf-check-box .conf-mark {
  width: 1.8em !important;
  left: -0.2em;
  top: -0.5em;
}

.conf-check-text {
  display: inline-block;
  float: left;
  padding: 0 1em;
}

.conf-mark {
  position: absolute;
  top: -0.8em;
  left: -0.4em;
  width: 2.6em !important;
  // display: none;
}

.conf-check.active .conf-mark {
  display: block;
}
.center {
  margin: 0px auto;
}
