.ember-power-calendar {
  box-sizing: border-box;
  position: relative;
}

.ember-power-calendar-nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.ember-power-calendar-nav * {
  box-sizing: border-box;
}

.ember-power-calendar-days,
.ember-power-calendar-days * {
  box-sizing: border-box;
}

.ember-power-calendar-nav-title {
  flex: 1;
  text-align: center;
}

.ember-power-calendar-row {
  display: flex;
  justify-content: space-between;
}
.ember-power-calendar-weekday {
  -webkit-appearance: none;
  flex: 1 1 100%;
  padding: 0;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
}
.ember-power-calendar-day {
  -webkit-appearance: none;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  flex: 1 1 100%;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.ember-power-calendar-nav-control {
  -webkit-appearance: none;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  font-size: inherit;
}

// Theme styles
.ember-power-calendar {
  font-size: 14px;
  line-height: 1.42857;
}
.ember-power-calendar-nav {
  line-height: 2;
}
.ember-power-calendar-nav-control {
  line-height: 1;
  font-size: 150%;
  &:focus {
    transform: scale(1.2);
  }
}
.ember-power-calendar-day--selected,
.ember-power-calendar-day--selected:not([disabled]):hover {
  font-weight: bold;
}

.ember-power-calendar-day--interactive[disabled] {
  opacity: 0.4;
}

@mixin ember-power-calendar(
  $cell-size: null,
  $cell-width: $cell-size,
  $cell-height: $cell-size,
  $cell-with-spacing-width: $cell-width + 2px,
  $row-padding-top: 0,
  $row-padding-bottom: 0,
  $row-padding-left: 0,
  $row-padding-right: 0,
  $row-width: $cell-with-spacing-width * 7 - 2px + $row-padding-left + $row-padding-right,
  $primary-color: #0078c9,
  $nav-button-color: $primary-color,
  $nav-button-color--focus: lighten($nav-button-color, 10%),
  $day-default-text-color: #bbb,
  $day-current-month-color: #f5f7fa,
  $day-weekday-text-color: #333333,
  $day-current-month-text-color: #656d78,
  $day-other-month-text-color--hover: $day-current-month-text-color,
  $day-focus-shadow-color: $primary-color,
  $day-today-color: #ecf1ff !important,
  $day-color--hover: #eee,
  $day-range-bookend-color: lighten($primary-color, 40%),
  $day-range-start-background-color: $day-range-bookend-color,
  $day-range-start-background-color--hover: $day-range-start-background-color,
  $day-range-end-background-color: $day-range-bookend-color,
  $day-range-end-background-color--hover: $day-range-end-background-color,
  $day-selected-color: lighten($primary-color, 50%),
  $day-selected-color--hover: $day-selected-color,
  $day-selected-text-color: #656d78,
  $day-selected-text-color--hover: $day-selected-text-color
) {
  width: $row-width;
  .ember-power-calendar-week:first-child {
    &[data-missing-days='1'] {
      padding-left: $cell-with-spacing-width * 1;
    }
    &[data-missing-days='2'] {
      padding-left: $cell-with-spacing-width * 2;
    }
    &[data-missing-days='3'] {
      padding-left: $cell-with-spacing-width * 3;
    }
    &[data-missing-days='4'] {
      padding-left: $cell-with-spacing-width * 4;
    }
    &[data-missing-days='5'] {
      padding-left: $cell-with-spacing-width * 5;
    }
    &[data-missing-days='6'] {
      padding-left: $cell-with-spacing-width * 6;
    }
  }
  .ember-power-calendar-week:last-child {
    &[data-missing-days='1'] {
      padding-right: $cell-with-spacing-width * 1;
    }
    &[data-missing-days='2'] {
      padding-right: $cell-with-spacing-width * 2;
    }
    &[data-missing-days='3'] {
      padding-right: $cell-with-spacing-width * 3;
    }
    &[data-missing-days='4'] {
      padding-right: $cell-with-spacing-width * 4;
    }
    &[data-missing-days='5'] {
      padding-right: $cell-with-spacing-width * 5;
    }
    &[data-missing-days='6'] {
      padding-right: $cell-with-spacing-width * 6;
    }
  }
  .ember-power-calendar-day,
  .ember-power-calendar-weekday {
    max-width: $cell-width;
    max-height: $cell-height;
    width: $cell-width;
    height: $cell-height;
  }
  .ember-power-calendar-weekdays,
  .ember-power-calendar-week {
    height: $cell-height + 2px;
    padding-left: $row-padding-left;
    padding-right: $row-padding-right;
  }
  .ember-power-calendar-day {
    color: $day-default-text-color;
  }
  .ember-power-calendar-weekdays {
    color: $day-weekday-text-color;
  }
  .ember-power-calendar-nav-control {
    color: $nav-button-color;
    &:focus {
      color: lighten($nav-button-color--focus, 10%);
    }
  }
  .ember-power-calendar-day--current-month {
    color: $day-current-month-text-color;
    background-color: $day-current-month-color;
  }
  .ember-power-calendar-day--today {
    background-color: $day-today-color;
  }
  .ember-power-calendar-day:not([disabled]):hover {
    background-color: $day-color--hover;
  }
  .ember-power-calendar-day--focused {
    box-shadow: inset 0px -2px 0px 0px $day-focus-shadow-color;
  }
  .ember-power-calendar-day--selected.ember-power-calendar-day--range-start {
    background-color: $day-range-start-background-color;
    &:hover {
      background-color: $day-range-start-background-color--hover;
    }
  }
  .ember-power-calendar-day--selected.ember-power-calendar-day--range-end {
    background-color: $day-range-end-background-color;
    &:hover {
      background-color: $day-range-end-background-color--hover;
    }
  }
  .ember-power-calendar-day--selected {
    background-color: $day-selected-color;
    color: $day-selected-text-color;
  }
  .ember-power-calendar-day--selected:not([disabled]):hover {
    background-color: $day-selected-color--hover;
    color: $day-selected-text-color--hover;
  }

  .ember-power-calendar-day--other-month:not([disabled]):hover {
    color: $day-other-month-text-color--hover;
  }
}
